import React from 'react';
import { Route } from 'react-router-dom';
import Reports from '../pages/Reports/Reports'
import Dashboards from '../pages/Dashboards/Dashboards'
import NavDrawer from '../components/NavDrawer';
import { Container, Row, Col } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import './Routes.css'

const routes = [
    { path: '/', name: 'Home', Component: Reports },
    { path: '/dashboards', name: 'Manage Dashboards', Component: Dashboards },
]

const Routes = () => {
    return (
        <Container fluid>
            <NavDrawer routes={routes} />
            <Row className="main-container vh-100">
                {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={path}>
                        {({ match }) => (
                            <CSSTransition
                                in={match != null}
                                timeout={300}
                                classNames="page"
                                unmountOnExit
                            >
                                <Col className="page" style={{ textAlign: 'center', color: '#073271' }}>
                                    <Component />
                                </Col>
                            </CSSTransition>
                        )}
                    </Route>
                ))}
            </Row>
        </Container>
    );
};

export default Routes;