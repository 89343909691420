const tokens = (
    state = {
        accessToken: null,
        idToken: null,
        refreshToken: null,
        role: null
    },
    action
) => {
    switch (action.type) {
        case 'SET_ACCESS_TOKEN':
            return {
                ...state,
                accessToken: action.payload,
            };
        case 'SET_ID_TOKEN':
            return {
                ...state,
                idToken: action.payload,
            };
        case 'SET_REFRESH_TOKEN':
            return {
                ...state,
                refreshToken: action.payload,
            };
        case 'SET_ROLE':
            return {
                ...state,
                role: action.payload,
            };
        default:
            return state;
    }
};

export default tokens;