import React from 'react';
import { Modal } from 'react-bootstrap';
import IframeComm from 'react-iframe-comm';

const ReportModal = ({ modalName, dashAttributes }) => {

    return (
        <div id="sdohModal">
            <Modal.Header closeButton>
                <Modal.Title>{modalName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IframeComm attributes={dashAttributes} />
            </Modal.Body>
        </div>
    );

}

export default ReportModal;