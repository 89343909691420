import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReportModal from '../../components/modals/ReportModal';
import { Card, Container, Col, Row, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import './Reports.css'

const Reports = () => {

    const idToken = useSelector((state) => state.tokens.idToken);
    const [isLoading, setIsLoading] = useState(true);
    const [embedUrlFlag, setEmbedUrlFlag] = useState(false);
    const isMountedRef = useRef(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (name, attributes) => {
        setModalName(name);
        setDashAttributes(attributes);
        setShow(true);
    }
    const [modalName, setModalName] = useState("");
    const [dashAttributes, setDashAttributes] = useState();

    //dev dashboard
    // const [sdohDashboards, setSdohDashboards] = useState([
    //     { 'name': 'Social Needs by Demographic Report', 'id': '555d4d1b-93ed-4652-9801-0df7789be9af', 'embedAttributes': '' },
    //     { 'name': 'SDOH Practice Screening Report', 'id': '52dcddf2-1a95-44b9-8473-81f626c1077b', 'embedAttributes': '' }, //MI specific
    // ]);

    //test dashboards
    // const [sdohDashboards, setSdohDashboards] = useState([
    //     { 'name': 'Social Needs by Demographic Report', 'id': 'mi__sdoh_patient_needs_dashboard__TEST', 'embedAttributes': '' },
    //     { 'name': 'SDOH Practice Screening Report', 'id': 'mi__sdoh_practice_screening_details__TEST', 'embedAttributes': '' }, //MI specific
    // ]);

    // //preprod dashboards
    // const [sdohDashboards, setSdohDashboards] = useState([
    //     { 'name': 'Social Needs by Demographic Report', 'id': 'mi__sdoh_patient_needs_dashboard__PREPROD', 'embedAttributes': '' },
    //     { 'name': 'SDOH Practice Screening Report', 'id': 'mi__sdoh_practice_screening_details__PREPROD', 'embedAttributes': '' }, //MI specific
    // ]);

    //prod dashboards
    const [sdohDashboards, setSdohDashboards] = useState([
        { 'name': 'Social Needs by Demographic Report', 'id': 'mi__sdoh_patient_needs_dashboard__PROD', 'embedAttributes': '' },
        { 'name': 'SDOH Practice Screening Report', 'id': 'mi__sdoh_practice_screening_details__PROD', 'embedAttributes': '' }, //MI specific
    ]);

    useEffect(() => {
        isMountedRef.current = true;

        if (embedUrlFlag) {
            setIsLoading(false);
        }

        return () => (isMountedRef.current = false);
    }, [embedUrlFlag]);

    useEffect(() => {
        isMountedRef.current = true;
        const getEmbedUrl = () => {
            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', idToken);

            sdohDashboards.forEach((dashboard, index) => {
                var raw = JSON.stringify({
                    dashboardId: dashboard.id
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };

                fetch(`${process.env.REACT_APP_SDOH_DASHBOARD_URL_API}/sdoh/get_quicksight_url`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        const parsedResult = JSON.parse(result);

                        let tempList = [...sdohDashboards];
                        tempList[index].embedAttributes = {
                            src: parsedResult,
                            width: '98%',
                            height: '660',
                            frameBorder: 1,
                            id: 'idIframe' + dashboard.id,
                        };

                        setSdohDashboards(tempList)
                        setEmbedUrlFlag(true)
                    })
                    .catch((error) => {
                        setEmbedUrlFlag(true)
                        let tempList = [...sdohDashboards];

                        tempList[index].embedAttributes = {
                            src: '',
                            width: '98%',
                            height: '660',
                            frameBorder: 1,
                            id: 'idIframe' + dashboard.id,
                        };
                        setSdohDashboards(tempList)
                    });
            })

        };
        getEmbedUrl();
        return () => (isMountedRef.current = false);
    }, [idToken]);

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        top: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#223c89',
                    }}
                >
                    <Loader type="ThreeDots" color="#223c89" height="80" width="80" />
                </div>
            )}
            {!isLoading &&
                <Container>
                    <h1>SDOH Reports</h1>
                    <Row>
                        <Col className="text-center">
                            <h6 style={{ marginBottom: '16px' }}>Please select from the following report options:</h6>
                        </Col>
                    </Row>
                    <Row xs={1} md={2} className="g-4">
                        {sdohDashboards.map((dashboard, index) => {
                            if (dashboard.embedAttributes) {
                                return (
                                    <Col className="item" key={index}>
                                        <Card>
                                            <Card.Header as="h5" className="text-center" onClick={() => handleShow(dashboard.name, dashboard.embedAttributes)}>{dashboard.name}</Card.Header>
                                        </Card>
                                    </Col>
                                )
                            }
                            return "";
                        })}
                    </Row>
                </Container>
            }
            <Modal show={show} onHide={handleClose} fullscreen className="reportmodal">
                <ReportModal modalName={modalName} dashAttributes={dashAttributes} />
            </Modal>
        </>
    );
};

export default Reports;