import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import './NavDrawer.css';
import { NavLink } from 'react-router-dom';

const NavDrawer = ({ routes }) => {
    const [isActive, setActive] = useState(false);
    const role = useSelector((state) => state.tokens.role);

    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <>
            {!isActive && (
                <div id="menu" onClick={handleToggle}>|||</div>
            )}
            <div id="sideNav" className={isActive ? "sidenav enter" : "sidenav"}>
                <div className="close" onClick={handleToggle}>&times;</div>
                {routes.map(route => {
                    if (route.path === "/dashboards" && !(role === "super admin" || role === "admin")) {
                        return "";
                    }
                    else {
                        return (
                            <Nav.Link
                                key={route.path}
                                as={NavLink}
                                to={route.path}
                                activeClassName="active"
                                exact
                                onClick={handleToggle}
                            >
                                {route.name}
                            </Nav.Link>
                        );
                    }
                })}
            </div>
        </>
    );
};

export default NavDrawer;