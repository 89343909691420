import React from 'react';
import { useSelector } from 'react-redux';

const Dashboards = () => {

    const role = useSelector((state) => state.tokens.role);

    if (!(role === "super admin" || role === "admin")) {
        return (
            <>
                <h1><b>404 : Page Not Found</b></h1>
            </>
        );
    }
    else {
        return (
            <>
                <h1><b>Dashboards Go Here</b></h1>
            </>
        );
    }

};

export default Dashboards;